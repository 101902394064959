.scroll::-webkit-scrollbar {
    width: 8px !important;
}

.scroll::-webkit-scrollbar-thumb {
    background: #827aa5;
    border-radius: 11px;
}

.scroll::-webkit-scrollbar-track {
    background: transparent;
}