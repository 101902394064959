.header-group {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

@media (max-width: 1580px) {
  .header-group {
      padding-top: 55px;
  }
}

.product-search {
    width: 533px;
    height: 44px;
    padding: 16px;
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    border: 1px solid #D2DAE8;
}

.button-group {
    width: 131px;
    height: 44px;
    padding: 17px;
    color: white !important;
    border-radius: 12px !important;
}

.header-modal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    font-weight: 500;
    width: 100%;
    height: 60px;
    padding: 0px 24px;
    background: #352A44;
}

.title-modal {
    display: flex;
    flex-direction: column;
    gap: 12px;
    background: #F3F2F7;
    padding: 24px;
}

.radio-option {
    width: 102px;
    height: 46px;
    padding: 12px;
    border-radius: 5px;
    border: 1px solid #DCDBE8;

}

.more-modal {
    width: 185px;
    background: white;
    position:absolute;
    height: 208px;
    border-radius: 8px;
    box-shadow: 0px 4px 16px 0px #0000003D;
}
