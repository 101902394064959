input[type="radio"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 0px;
    outline: none; 
  }
  
  input[type="radio"]:checked {
    border-color: transparent; 
    background-color: transparent; 
  }