.modalBackdrop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}

.modalBackdrop.hidden {
  display: none;
}

.modal {
  position: relative;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  width: 500px;
  max-width: 90%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.modalTitle {
  font-weight: "600";
  font-size: 32px;
  color: 'black';
}

.modal.hidden {
  display: none;
}

.closeIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 1.5rem;
  color: #888;
}

.closeIcon:hover {
  color: #555;
}

.codeInputs {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.codeInputs input {
  width: 50px;
  height: 60px;
  font-size: 1.75rem;
  text-align: center;
  border-radius: 8px;
  outline: none;
  border: 1px solid #ddd;
  margin: 0 5px;
  padding: 10px;
  transition: border-color 0.2s, box-shadow 0.2s;
}

.codeInputs input:focus {
  border-color: #FB3B8C;
  box-shadow: 0 0 0 2px rgba(251, 59, 140, 0.25);
}

.button {
  cursor: pointer;
  border: none;
  outline: none;
  padding: 12px 24px;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  transition: background-color 0.3s, color 0.3s;
}

.button.buttonPrimary {
  background-color: #FB3B8C;
  color: #fff;
}

.button.buttonPrimary:hover {
  background-color: #d32f6d;
}

.button.buttonPrimary:disabled {
  background-color: #f29db8;
  cursor: not-allowed;
}
