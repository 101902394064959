.custom-sketch-picker {
    height: 350px !important;
    margin-top: 40px;
    width: fit-content !important;
    border-radius: 12px !important;
}


.custom-sketch-picker > div:first-child  {
    border-radius: 8px !important;
    padding-bottom: 0px !important;
    height: 250px !important;

}
.custom-sketch-picker > div:first-child > div {
    height: 238px !important;
    border-radius: 8px !important;
    overflow: hidden;
}
.saturation-white,
.saturation-black {
    height: 238px !important;
    border-radius: 8px !important;
}

.hue-horizontal {
    border-radius: 24px !important;
}

.saturation-white > div:last-child > div {
    width: 32px !important;
    height: 32px !important;
    box-shadow: none !important;
    border: 5px solid white;
    background: transparent;
}