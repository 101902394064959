.buttonText {
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
}

.buttonText.buttonPrimary {
  color: #007bff;
}

.buttonText.buttonPrimary:hover {
  color: #0069d9;
}

.button {
  cursor: pointer;
  border: none;
  outline: none;
  color: #fff;
  padding: 10px 20px;
  border-radius: 4px;
}

.button.buttonPrimary {
  background-color: #007bff;
}

.button.buttonPrimary:hover {
  background-color: #0069d9;
}

.button.buttonPrimary:disabled {
  background-color: #b3d7ff;
  cursor: not-allowed;
}
